import cloneDeep from "lodash-es/cloneDeep";
import { isString, isNull, isUndefined } from "assertate";
// Need this to stop tracking reactivity while we augment a thing.
// eslint-disable-next-line vue/prefer-import-from-vue
import { pauseTracking, enableTracking } from "@vue/reactivity";
import type { UnknownRecord } from "type-fest";

/**
 * Internal Tracking of all generated UIDs, so we don't get accidental collision.
 * @private
 */
const _UIDS: Set<string> = new Set<string>([""]);

/**
 * Generate a new Unique ID string.
 * @param [prefix] - Will prefix the generated uid with `${prefix}-` if supplied
 */
export function getUID(prefix?: string): string {
  let uid: string = "";
  while (_UIDS.has(uid)) {
    // User browser builtin
    if (window.isSecureContext) {
      uid = crypto.randomUUID();
    } else {
      // if browser builtin isn't available, then use our own thing
      uid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r: number = (Math.random() * 16) | 0,
          v: number = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
    if (prefix) {
      uid = `${prefix}-${uid}`;
    }
  }
  _UIDS.add(uid);
  return uid;
}

/**
 * Will apply a UID to an object at the provided key
 * @param data - the object to have a UID applied to
 * @param [key='uid'] - the property name to be used
 * @param [prefix] - an optional prefix for the UID.
 */
export function applyUID(data: Record<string, unknown>, key: string = "uid", prefix?: string): Record<string, unknown> {
  // if the uid isn't defined, OR if it is defined, but isn't in the global registry.
  if (typeof data[key] === "undefined" || (isString(data[key]) && !_UIDS.has(<string>data[key]))) {
    data[key] = getUID(prefix);
  }

  return data;
}

/**
 * Will remove a UID on an object at the provided key and release it from the set
 * @param data - the object to have a UID applied to
 * @param [key='uid'] - the property name to be used
 */
export function removeUID(data: Record<string, unknown>, key: string = "uid"): Record<string, unknown> {
  const uid = data[key];
  if (uid) {
    delete data[key];
  }
  return data;
}

/**
 * Maps over an array of objects and returns a new array with UIDs applied.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 * @param [prefix] - optional prefix for the UID
 */
export function mapApplyUID(
  data: Record<string, unknown>[],
  key: string = "uid",
  prefix?: string,
): Record<string, unknown>[] {
  return data.map((el: Record<string, unknown>): Record<string, unknown> => {
    return applyUID(el, key, prefix);
  });
}

/**
 * Maps over an array of objects and returns a new array with UIDs removed.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 */
export function mapRemoveUID(data: Record<string, unknown>[], key: string = "uid"): Record<string, unknown>[] {
  return data.map((el: Record<string, unknown>): Record<string, unknown> => {
    return removeUID(el, key);
  });
}

/**
 * Deep clones and maps over an array of objects and returns a new array with UIDs applied.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 * @param [prefix] - optional prefix for the UID
 */
export function cloneMapApplyUID(
  data: Record<string, unknown>[],
  key: string = "uid",
  prefix?: string,
): Record<string, unknown>[] {
  return mapApplyUID(cloneDeep(data), key, prefix);
}

/**
 * Deep clones and maps over an array of objects and returns a new array with UIDs removed.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 */
export function cloneMapRemoveUID(data: Record<string, unknown>[], key: string = "uid"): Record<string, unknown>[] {
  return mapRemoveUID(cloneDeep(data), key);
}

/**
 * This is why you don't use index as a key, now we have to commit crimes.
 */
const MAGIC_KEY = Symbol("magicKey");
const MAGIC_MAP = new Map<string | number | symbol, string>();

const MAGIC_MAP_TYPES = ["string", "number", "symbol"];

/**
 * Test to see if a record has a magic key or not.
 * @param record
 */
export function hasMagicKey(record: unknown): boolean {
  if (isNull(record) || isUndefined(record)) return false;
  if (record && typeof record === "object") return typeof (<UnknownRecord>record)?.[MAGIC_KEY] === "string";
  if (MAGIC_MAP_TYPES.includes(typeof record)) return MAGIC_MAP.has(<string | number | symbol>record);
  return false;
}

/**
 * Pass in anything, and get a consistent unique ID back.
 *
 * Future Alex, I apologize for the dirty hack.
 * @param record
 */
export function getMagicKey<T>(record: T): string {
  pauseTracking();
  if (isUndefined(record) || isNull(record)) {
    enableTracking();
    return "undefined";
  }
  if (record && typeof record === "object") {
    if (!hasMagicKey(record)) {
      __dangerouslySetMagicKey("OBJECT", <UnknownRecord>record, getUID());
    }
    enableTracking();
    return <string>(<T & { [MAGIC_KEY]: string }>record)[MAGIC_KEY];
  }
  if (MAGIC_MAP_TYPES.includes(typeof record)) {
    if (!hasMagicKey(record)) {
      __dangerouslySetMagicKey("NOT_OBJECT", <string | number | symbol>record, getUID());
    }
    enableTracking();
    return <string>MAGIC_MAP.get(<string | number | symbol>record);
  }
  enableTracking();
  return "undefined";
}

export function cloneMagicKey<T>(from: T, to: T): void {
  if (typeof from === "object" && hasMagicKey(from) && !hasMagicKey(to)) {
    __dangerouslySetMagicKey("OBJECT", <UnknownRecord>to, getMagicKey(from));
  }
}

interface __MagicKeyTypes {
  NOT_OBJECT: string | number | symbol;
  OBJECT: UnknownRecord;
}

/**
 * This is to only be used if you know what you are doing. If you don't know what this is doing, don't use it.
 * @param _type
 * @param record
 * @param uid
 */
function __dangerouslySetMagicKey<K extends keyof __MagicKeyTypes>(
  _type: K,
  record: __MagicKeyTypes[K],
  uid: string,
): void {
  switch (_type) {
    case "OBJECT":
      Object.defineProperty(record, MAGIC_KEY, {
        value: uid,
        configurable: false,
        enumerable: true,
        writable: false,
      });
      return;
    case "NOT_OBJECT":
      MAGIC_MAP.set(<string | number | symbol>record, uid);
      return;
  }
}
