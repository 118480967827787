import { z } from "zod";
import type { DepartmentDto, EmployeeLookUpViewDto, TeamDocumentDto } from "~/connectables/fetch/employeeManagement.ts";
import { LocalFetchError } from "~/utils/errorObjects.ts";

export enum ErrorResponses {
  DuplicateEntity = "DuplicateEntity",
  Error = "Error",
  Exception = "Exception",
  InProgress = "InProgress",
  InUse = "InUse",
  InvalidPage = "InvalidPage",
  NoChange = "NoChange",
  NoContent = "NoContent",
  NotFound = "NotFound",
  NotValid = "NotValid",
  Success = "Success",
}

export function isFetchErrorResponse(value: unknown): value is LocalFetchError<ErrorResponse> {
  if (!(value instanceof LocalFetchError)) return false;
  return isApiErrorResponse(value.body);
}

export function isDuplicateEntityError(
  value: unknown,
): value is LocalFetchError<ErrorResponse<ErrorResponses.DuplicateEntity>> {
  if (!isFetchErrorResponse(value)) return false;
  return value.body?.kind === <string>ErrorResponses.DuplicateEntity || value.body?.kind === "duplicateEntity";
}

export function isInUseError(value: unknown): value is LocalFetchError<ErrorResponse<ErrorResponses.InUse>> {
  if (!isFetchErrorResponse(value)) return false;
  return value.body?.kind === <string>ErrorResponses.InUse || value.body?.kind === "inUse";
}

export type ErrorResponse<T_Err extends DataStatus | ErrorResponses = DataStatus | ErrorResponses> = {
  message: string;
  kind: T_Err;
  exception: string | null;
  stackTrace: string | null;
  exceptionType: string;
  innerException: string | null;
  validationErrors: {
    [key: string]: string[];
  } | null;
};

export type PossibleErrorResponses = ErrorResponse | string | undefined | null | Error;

export const errorResponseSchema = z.object({
  message: z.string(),
  kind: z.nativeEnum(ErrorResponses),
  exception: z.string().nullable(),
});

export function isApiErrorResponse(value: unknown): value is ErrorResponse {
  const validation = errorResponseSchema.safeParse(value);
  return validation.success;
}

export type ValidatorObject = {
  check: (value: unknown) => boolean;
  message: string | (() => string);
};

export type Exception = {
  message?: string | null;
  data?: {
    [key: string]: unknown;
  } | null;
  innerException?: Exception;
  helpLink?: string | null;
  source?: string | null;
  hResult?: number;
  stackTrace?: string | null;
};

export type DataStatus =
  | "Success"
  | "success"
  | "NotValid"
  | "notValid"
  | "Exception"
  | "exception"
  | "Error"
  | "error"
  | "InUse"
  | "inUse"
  | "NotFound"
  | "notFound"
  | "NoChange"
  | "noChange"
  | "NoContent"
  | "noContent"
  | "InProgress"
  | "inProgress"
  | "InvalidPage"
  | "invalidPage"
  | "Duplicate"
  | "duplicate"
  | "DuplicateEntity"
  | "duplicateEntity";

export type DataResponse<T_ReturnData> = {
  data?: T_ReturnData;
  successful?: boolean;
  failed?: boolean;
  isInUse?: boolean;
  isNotFound?: boolean;
  failedValidation?: boolean;
  message?: string | null;
  exception?: Exception;
  status?: DataStatus;
  innerResponses?: DataResponse<T_ReturnData>[] | null;
};

export enum TaskListBulkEditType {
  Skill = "skill",
  WeekInterval = "weekInterval",
  Calculation = "calculationType",
}

export enum WorkpackStatus {
  unassigned = "unassigned",
  assigned = "assigned",
  pending = "pending",
  inProgress = "in-progress",
}
export type MultiEmployeeTeamDepartmentEmployeeLookUpViewDto = EmployeeLookUpViewDto & { dataType: "employee" };
export type MultiEmployeeTeamDepartmentTeamDocumentDto = TeamDocumentDto & {
  number: number;
  fullEmployees?: MultiEmployeeTeamDepartmentEmployeeLookUpViewDto[];
  name: string;
  dataType?: string;
};
export type MultiEmployeeTeamDepartmentDepartmentDto = DepartmentDto & { dataType: "department" };
export type MultiEmployeeTeamDepartmentListItem =
  | MultiEmployeeTeamDepartmentTeamDocumentDto
  | MultiEmployeeTeamDepartmentDepartmentDto
  | MultiEmployeeTeamDepartmentEmployeeLookUpViewDto;
