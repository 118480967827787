<script setup lang="ts">
const { t } = useI18n();
const head = useLocaleHead({ addDirAttribute: true, addSeoAttributes: true });

definePageMeta({
  allowAnonymous: true,
});

const directionRef = computed(() => head.value.htmlAttrs?.dir);
useHead({
  title: "404 - " + t("errors.pages.404.title"),
});

const router = useRouter();

function handleBack() {
  if (window.history.state.back === null) {
    router.push({ path: "/" });
  } else {
    router.back();
  }
}
</script>

<template>
  <Html v-bind="head.htmlAttrs" />
  <main class="flex flex-col justify-center items-center w-full h-screen gap-14 bg-ngray-7">
    <a href="/"><img src="~/assets/svg/logo.svg" class="w-52 h-16" :alt="$t('alt.logo')" /></a>
    <section
      class="flex flex-col items-center justify-center border-2 border-ngray-6 rounded-lg p-4 min-w-[420px] min-h-[491px] gap-4 bg-ngray-8"
    >
      <h1 class="text-nred-3 text-[115px] font-extrabold leading-none">404</h1>

      <h3 class="text-nblue-1 text-lg font-bold tracking-[5px] leading-[25px]">
        {{ t("errors.pages.404.title") }}
      </h3>

      <p class="text-sm font-normal text-nblue-1 w-[230px] text-center">
        {{ t("errors.pages.404.message") }}
      </p>
      <button
        class="text-ngray-8 bg-nblue-2 hover:bg-nblue-1 cursor-pointer rounded-full px-6 py-3 font-semibold text-center"
        @click="handleBack"
      >
        {{ t("buttons.goBack") }}
      </button>
      <slot />
    </section>
  </main>
</template>
<style scoped lang="postcss">
* {
  direction: v-bind("directionRef");
}
</style>
