<script setup lang="ts">
import { useGlobalNotification, NotificationType, NotificationMessage } from "~/store/global-notification.ts";
import { vAutoAnimate } from "@formkit/auto-animate/vue";

const { t } = useI18n();

const notification = useGlobalNotification();

const messageList = reactive({
  [NotificationType.Pending]: t("notifications.statusPending"),
  [NotificationType.Success]: t("notifications.statusSuccess"),
  [NotificationType.Error]: t("notifications.statusError"),
  [NotificationType.Warning]: t("notifications.statusWarning"),
  /* Info notification won't be triggered if there's no message, but TS still wants this. */
  [NotificationType.Info]: "",
});

function handleClose(item: NotificationMessage) {
  notification.clearMessage(item);
}

function handleHide(item: NotificationMessage) {
  notification.hideMessage(item);
}

const activeNotifications = computed(() =>
  notification.notificationQueue.reduce((acc, el) => {
    if (el.active) {
      acc.push(el);
    }
    return acc;
  }, [] as NotificationMessage[]),
);
</script>

<template>
  <TransitionGroup name="notification" tag="div">
    <div
      v-for="item in activeNotifications"
      :key="item.id"
      class="notification"
      :data-testid="`notification-${item.type}`"
      :class="item.type"
    >
      <div class="flex w-full items-baseline content-baseline justify-center">
        <base-icon
          v-if="item.type === NotificationType.Pending"
          name="fa6-solid:spinner"
          size="1em"
          class="animate-spin m-[5px] self-center"
        />
        <base-icon v-if="item.type === NotificationType.Info" class="self-center" name="mdi:information" />
        <p class="message">{{ item.message !== "" ? $t(item.message) : messageList[item.type] }}</p>
        <button
          v-if="item.type === NotificationType.Error && !item.persist"
          class="notification-button"
          :aria-label="$t('buttons.close')"
          @click="handleClose(item)"
        >
          <base-icon name="mdi:window-close" size="1.25em" aria-hidden="true" />
        </button>
        <button
          v-if="item.persist"
          class="notification-button"
          :aria-label="$t('buttons.hide')"
          @click="handleHide(item)"
        >
          <base-icon name="mdi:hide-outline" size="1em" aria-hidden="true" />
        </button>
      </div>
      <div v-auto-animate class="flex items-center justify-center">
        <ProgressBar v-if="item.showProgress && notification.currentActive?.id === item.id" :value="item.progress" />
      </div>
    </div>
  </TransitionGroup>
</template>
<style scoped lang="postcss">
.notification {
  @apply flex flex-col items-center p-2 gap-2 shadow-xl;
  justify-content: center;
  z-index: 10000;
  position: fixed;
  inset-block-start: calc(3rem);
  inset-inline: 50%;
  translate: -50%;
  width: max(50vw, 320px);
  border-radius: 0.5rem;
  transition:
    inset-block-start 0.3s,
    max-height 0.3s;
  max-height: 52px;
  overflow-y: hidden;
}

.notification-button {
  @apply: z-50 h-full flex flex-col justify-center;
  position: absolute;
  inset-block-start: 0.75rem;
  inset-inline-end: 1rem;
}

.message {
  max-height: 50px;
  padding-inline: 2rem;
  padding-block: 0.5rem;
  padding-inline-start: 1ch;
  overflow-y: hidden;

  transition: max-height 0.3s;

  .notification:nth-child(1) &,
  .notification-leave-active ~ .notification:nth-child(2) & {
    max-height: 180px;
  }
}

.notification:nth-child(1) {
  inset-block-start: calc(3rem - 32px);
  z-index: 10006;
  max-height: 200px;
  overflow-y: initial;
}

.notification:nth-child(2) {
  inset-block-start: calc(3rem - 19px);
  z-index: 10005;

  .notification-leave-active ~ & {
    inset-block-start: calc(3rem - 32px);
    max-height: 200px;
    overflow-y: initial;
  }
}
.notification:nth-child(3) {
  inset-block-start: calc(3rem - 11px);
  z-index: 10004;

  .notification-leave-active ~ & {
    inset-block-start: calc(3rem - 19px);
  }
}
.notification:nth-child(4) {
  inset-block-start: calc(3rem - 6px);
  z-index: 10003;

  .notification-leave-active ~ & {
    inset-block-start: calc(3rem - 11px);
  }
}
.notification:nth-child(5) {
  inset-block-start: calc(3rem - 3px);
  z-index: 10002;

  .notification-leave-active ~ & {
    inset-block-start: calc(3rem - 6px);
  }
}
.notification:nth-child(6) {
  inset-block-start: calc(3rem - 1px);
  z-index: 10001;

  .notification-leave-active ~ & {
    inset-block-start: calc(3rem - 3px);
  }
}

.notification-leave-active ~ .notification:nth-child(7) {
  inset-block-start: calc(3rem - 1px);
}

.success {
  @apply bg-ngreen-2;
  @apply text-ngray-8;
}
.pending {
  @apply bg-nyellow-2;
  @apply text-ngray-1;
}
.warning {
  @apply bg-nyellow-2;
}
.error {
  @apply bg-nred-2;
  @apply text-ngray-8;
}
.info {
  @apply bg-nblue-3;
  @apply text-ngray-1;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  filter: blur(4px);
  transform: translateY(-50px);
}
.notification-move,
.notification-enter-active,
.notification-leave-active {
  transition:
    inset-block-start 0.3s,
    max-height 0.3s,
    opacity 0.3s,
    transform 0.3s,
    filter 0.3s;
}
</style>
