import { defineStore } from "pinia";
import { ref } from "vue";

export const useDraggingStatusStore = defineStore("draggingStatus", () => {
  const dragging = ref<boolean>(false);

  function startDragging() {
    dragging.value = true;
  }
  function endDragging() {
    dragging.value = false;
  }

  return {
    startDragging,
    endDragging,
    isDragging: readonly(dragging),
  };
});
