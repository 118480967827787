/**
 * Type Validation
 */

export function isNumberOrString(value: unknown): value is number | string {
  return typeof value === "number" || typeof value === "string";
}

/**
 * Form input validation functions
 */

const charMinMax = (value: unknown, min = 0, max = 50) => {
  // check value is a string and has a length between min and max (inclusive)
  return typeof value === "string" && value.length >= min && value.length <= max;
};

const isEmail = (value: unknown) => {
  // check value is a string and matches a simple email regex
  return typeof value === "string" && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
};

const isRequiredAndNotEmpty = (value: unknown) => {
  // check value is a string and is not empty
  return value !== undefined && value !== null && value !== "";
};

const isRequiredAndNotZero = (value: unknown) => {
  // check value is a number and is not zero
  return value !== undefined && value !== null && value !== 0;
};

const isRequired = (value: unknown) => {
  // check value is not undefined or null
  return value !== undefined && value !== null;
};

const isNumber = (value: unknown) => {
  // check value is a number
  return typeof value === "number";
};

const isNumberAndNotZero = (value: unknown) => {
  // check value is a number and is not zero
  return typeof value === "number" && value !== 0;
};

const isNumberAndNotNegative = (value: unknown) => {
  // check value is a number and is not negative
  return typeof value === "number" && value >= 0;
};

const isNumberAndNotNegativeAndNotZero = (value: unknown) => {
  // check value is a number and is not negative and is not zero
  return typeof value === "number" && value > 0;
};

const isNumberAndNotNegativeAndNotZeroAndNotNaN = (value: unknown) => {
  // check value is a number and is not negative and is not zero and is not NaN
  return typeof value === "number" && value > 0 && !Number.isNaN(value);
};

const charMax = (value: unknown, max = 50) => {
  // check value is a string and has a length less than or equal to max
  return typeof value === "string" && value.length <= max;
};

const isDate = (value: unknown) => {
  // check value is a date
  return value instanceof Date;
};

const isDateAndNotZero = (value: unknown) => {
  // check value is a date and is not zero
  return value instanceof Date && value.getTime() !== 0;
};

const isDateAndNotZeroAndNotNaN = (value: unknown) => {
  // check value is a date and is not zero and is not NaN
  return value instanceof Date && value.getTime() !== 0 && !Number.isNaN(value.getTime());
};

export const validate = {
  charMinMax,
  isEmail,
  isRequiredAndNotEmpty,
  isRequiredAndNotZero,
  isRequired,
  isNumber,
  isNumberAndNotZero,
  isNumberAndNotNegative,
  isNumberAndNotNegativeAndNotZero,
  isNumberAndNotNegativeAndNotZeroAndNotNaN,
  charMax,
  isDate,
  isDateAndNotZero,
  isDateAndNotZeroAndNotNaN,
};
